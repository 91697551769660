import {
  GET_CONCEPTS,
  CONCEPT_ERROR,
  DELETE_CONCEPT,
  ADD_CONCEPT,
  UPDATE_CONCEPT,
} from 'src/actions/types'

const initialState = {
  concepts: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_CONCEPTS:
      return {
        ...state,
        concepts: payload.data,
        filters: payload.filters,
        loading: false,
      }
    case ADD_CONCEPT:
      return {
        ...state,
        concepts: { data: [payload, ...state.concepts.data] },
        loading: false,
      }
    case UPDATE_CONCEPT: {
      let index = state.concepts.data.findIndex((concept) => concept.id === payload.id)
      state.concepts.data[index] = { ...state.concepts.data[index], ...payload }
      return {
        ...state,
        concepts: { data: [...state.concepts.data], ...state.concepts },
        loading: false,
      }
    }
    case DELETE_CONCEPT:
      return {
        ...state,
        concepts: { data: state.concepts.data.filter((concept) => concept.id !== payload) },
        loading: false,
      }
    case CONCEPT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
