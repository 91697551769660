import { GET_MY_PAYMENTS, MY_PAYMENTS_ERROR } from 'src/actions/types'

const initialState = {
  myPayments: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_MY_PAYMENTS:
      return {
        ...state,
        myPayments: payload,
        filters: payload.filters,
        loading: false,
        error: {},
      }

    case MY_PAYMENTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
