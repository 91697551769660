import {
  ADD_BUDGET,
  DELETE_BUDGET,
  GET_BUDGETS,
  BUDGET_ERROR,
  UPDATE_BUDGET,
  IMPORT_BUDGETS_TEMPLATE_EXCEL,
  IMPORT_BUDGETS_TEMPLATE_EXCEL_PROGRESS,
} from 'src/actions/types'

const initialState = {
  budgets: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
  progress: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_BUDGETS:
      return {
        ...state,
        budgets: payload.data,
        filters: payload.filters,
        loading: false,
      }
    case ADD_BUDGET:
      return {
        ...state,
        budgets: { data: [payload, ...state.budgets.data] },
        loading: false,
      }
    case UPDATE_BUDGET: {
      let index = state.budgets.data.findIndex((budget) => budget.id === payload.id)
      state.budgets.data[index] = { ...state.budgets.data[index], ...payload }
      return {
        ...state,
        budgets: { data: [...state.budgets.data], ...state.budgets },
        loading: false,
      }
    }
    case DELETE_BUDGET:
      return {
        ...state,
        budgets: { data: state.budgets.data.filter((budget) => budget.id !== payload) },
        loading: false,
      }
    case IMPORT_BUDGETS_TEMPLATE_EXCEL:
      return {
        ...state,
        progress: 0,
      }
    case IMPORT_BUDGETS_TEMPLATE_EXCEL_PROGRESS:
      return {
        ...state,
        progress: payload,
      }
    case BUDGET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
